.collectionBanner {
  width: 100%;
  height: 250px;
  margin-top: 70px;
}

/* .collectionBanner img {
  width: 100%;
  height: 100%;
} */

@media screen and (min-width: 840px) {
  .collectionDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    flex-direction: row;
    /* flex-wrap: wrap; */
    padding: 40px;
  }
}

@media screen and(max-width:840px) {
  .collectionDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
  }
}

.avatarImage {
  width: 80px;
  height: 80px;
  background-color: white;
  /* z-index: 20; */
  /* position: relative; */
  margin-top: 30px;
  border-radius: 100%;
  /* top: -10px;
  left: 50%; */
}

.avatarImage img {
  width: 80px;
  height: 80px;
  scale: 0.85;
  border-radius: 100%;
}

.collectionDetails .collectionName {
  font-weight: bolder;
  color: white;
  font-size: 45px;
  /* max-width: 50%; */
}

.collectionDescription {
  font: bold;
  color: white;
  font-size: 20px;
  margin-top: -30px;
  /* max-width: 50%; */
}

.collectionDetails .collectionLinks {
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  height: 30px;
  width: 100px;
  margin-left: 10px;
  align-items: center;
  gap: 30px;
}

.marketText {
  font-weight: bolder;
  color: white;
  font-size: 30px;
  padding: 15px;
  text-decoration: underline;
}

.collectionLinks svg {
  cursor: pointer;
}

.nftMarketWrap {
  display: flex;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
}
