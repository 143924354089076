.connect_options_wrap{
    background-color: black;
    height: 800px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.wallet_option{
    height: 250px;
    width: 350px;
    background-color: #343444;
    border:none;
    border-radius: 10px;
}

.wallet_option img {
    display: block;
    margin: auto;
    padding-top: 30px;
}

.wallet_option p{
    text-align: center;
    color: white;
    margin-top: 5px;
    font-size: 30px;
}

.wallet_option button{
    display: block;
    margin: auto;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap:20px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Urbanist', sans-serif;
    align-items: center;
    width: auto;
    border : 1px solid rgba(81, 66, 252, 1);
    transition: all .4s ease-in-out;
    cursor: pointer;
}

.wallet_option button:hover{
    border:none;
    background-color:white;
    color:#343444;
    transform: scale(1.2);
}