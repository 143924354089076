@media screen and (min-width:950px) {
    .userDetailsBlock{
        height: auto;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        /* width: 100%; */
    }
    
    .userProfileDetails{
        display: flex;
        flex-direction: row;
        background-color: #343444;
        height: 300px;
        width: 90%;
        border-radius: 10px;
        gap: 30px;
    }
    
    .userImageBlock{
        display: flex;
        align-items: center
    }
    
    .userImageBlock img {
        margin: auto;
        height: 80%;
        width: 95%;
        border-radius: 10px;
        margin-left: 30px;
    }
    
    
    .userNameSection{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    
    .userDetailsBlock h3 {
        color :white;
        font-size: 35px;
    }
    
    .userDetailsBlock p {
        margin-top: -15px;
        color : #EBEBEB;
        font-size: 18px;
        width: 400px;
    }
    
    .walletAddressDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: 200px;
        background-color: #141423;
        border-radius: 20px;
        align-items: center;
        gap: 30px;
        color: white;
    }
    
    .walletAddressDiv p {
        text-align: center;
        font-size: 15px;
        margin-top: 13px;
        width: auto;
    }
    
    .walletAddressDiv svg{
        cursor: pointer;
    }
    .editProfileDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: 200px;
        background-color: #141423;
        border-radius: 20px;
        align-items: center;
        gap: 30px;
        cursor: pointer;
        color: white;
    }
    
    .editProfileDiv p {
        text-align: center;
        font-size: 15px;
        margin-top: 13px;
        width: auto;
    }
    
    .editProfileDiv svg{
        cursor: pointer;
    }
    
    .listedNftSection{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        padding-bottom: 30px ;
    }
    
    .nftSectionHeader{
        font-size: 30px;
        color: white;
        text-align: center;
    }
    
    .nftTypeSection{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap:30px
    }
    
    .nftOwnershipType{
        height: 20px;
        width: auto;
        padding: 10px;
        background-color: #343444;
        font-size: 15px;
        text-align: center;
        border-radius: 10px;
        color: white;
        transition: all .4s ease-in-out;
        cursor: pointer;
    }
    
    .nftOwnershipType:hover{
        background-color: white;
        color: black;
        transform: scale(1.2);
        transition: all .4s ease-in-out;
    }
    .nftOwnershipTypeActive{
        height: 20px;
        width: auto;
        padding: 10px;
        background-color: white;
        font-size: 15px;
        cursor: pointer;
        text-align: center;
        border-radius: 10px;
        color: black;
        transform: scale(1.2);
        transition: all .4s ease-in-out;
    }
}

@media screen and (max-width:949px){
    .userDetailsBlock{
        height: auto;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    
    .userProfileDetails{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #343444;
        height: auto;
        width: 90%;
        border-radius: 10px;
    }
    
    .userImageBlock{
        display: flex;
        align-items: center;
        height: auto;
        padding: 10px;
    }
    
    .userImageBlock img {
        margin: auto;
        height: auto;
        width: 200px;
        border-radius: 10px;
        margin-left: 30px;
    }
    
    
    .userNameSection{
        display: flex;
        flex-direction: column;
        padding:20px;
    }
    
    .userDetailsBlock h3 {
        color :white;
        font-size: 35px;
    }
    
    .userDetailsBlock p {
        margin-top: -15px;
        color : #EBEBEB;
        font-size: 18px;
        width: 400px;
    }
    
    .walletAddressDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: auto;
        padding-right: 10px;
        padding-left: 10px;
        background-color: #141423;
        border-radius: 20px;
        align-items: center;
        gap: 30px;
        color: white;
    }
    
    .walletAddressDiv p {
        text-align: center;
        font-size: 15px;
        margin-top: 13px;
        width: auto;
    }
    
    .walletAddressDiv svg{
        cursor: pointer;
    }
    .editProfileDiv{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 40px;
        width: auto;
        padding-right: 10px;
        padding-left: 10px;
        background-color: #141423;
        border-radius: 20px;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        color: white;
    }
    
    .editProfileDiv p {
        text-align: center;
        font-size: 15px;
        margin-top: 13px;
        width: auto;
    }
    
    .editProfileDiv svg{
        cursor: pointer;
    }
    
    .listedNftSection{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
        padding-bottom: 30px ;
    }
    
    .nftSectionHeader{
        font-size: 30px;
        color: white;
        text-align: center;
    }
    
    .nftTypeSection{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        gap:30px
    }
    
    .nftOwnershipType{
        height: 20px;
        width: auto;
        padding: 10px;
        background-color: #343444;
        font-size: 15px;
        text-align: center;
        border-radius: 10px;
        color: white;
        transition: all .4s ease-in-out;
        cursor: pointer;
    }
    
    .nftOwnershipType:hover{
        background-color: white;
        color: black;
        transform: scale(1.2);
        transition: all .4s ease-in-out;
    }
    .nftOwnershipTypeActive{
        height: 20px;
        width: auto;
        padding: 10px;
        background-color: white;
        font-size: 15px;
        cursor: pointer;
        text-align: center;
        border-radius: 10px;
        color: black;
        transform: scale(1.2);
        transition: all .4s ease-in-out;
    }
}

