.nft_wrap_card {
  height: auto;
  width: 300px;
  border-radius: 20px;
  border: none;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #343444;
}

.nft_image_view {
  background-color: #7a798a;
  display: block;
  height: 270px;
  width: 290px;
  border-radius: 20px;
  border: none;
  margin: auto;
}

.nft_image_view img {
  margin-top: 10px;
  display: block;
  margin: auto;
  height: 80%;
  width: 80%;
  border-radius: 10px;
}

.nft_image_view video {
  background-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  height: 90%;
  width: 95%;
  border-radius: 10px;
}

.nft_name {
  text-align: left;
  margin-left: 10px;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.nft_creator_section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
}

.creator_image_div {
  height: 60px;
  width: 60px;
  background-color: #7a798a;
  border-radius: 15px;
  margin-left: 5px;
}

.creator_image_div img {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding-top: 5px;
  height: 80%;
  width: 80%;
}

.creator_name_details {
  display: flex;
  flex-direction: column;
  margin-top: -25px;
  width: 50%;
}

.creator_name_details p {
  color: #7a798a;
}
.creator_name_details b {
  color: white;
}

.network_tag {
  float: right;
  height: 30px;
  width: 60px;
  background-color: #5142fc;
  border-radius: 10px;
}

.network_tag p {
  text-align: center;
  color: white;
  font-weight: 200;
  margin-top: 5px;
}

.horizontal_line {
  margin-top: 20px;
  height: 0.1px;
  width: 90%;
  color: black;
  border: 0.2px solid black;
  content: "";
}

.price_section {
  display: flex;
  flex-direction: column;
  margin-top: -25px;
}

.price_section p {
  color: #7a798a;
}
.price_section b {
  color: white;
  font-size: 22px;
}

.viewMoreButton {
  background-color: white;
  border-radius: 10px;
  height: 50px;
  width: 80%;
  display: block;
  margin: auto;
  color: black;
  text-align: center;
  outline: none;
  border: 0;
}
