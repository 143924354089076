/* @tailwind base;
@tailwind components;
@tailwind utilities; */

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Urbanist", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #141423;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
