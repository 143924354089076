@media screen and (min-width: 850px) {
  .mainHeader {
    position: fixed;
    top: 0;
    text-decoration: none;
    height: 70px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #141423;
    color: white;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 50%);
    /*background: radial-gradient(30.8% 30.8% at 57.79% 54.71%, rgba(43, 24, 103, 0.24) 0%, rgba(56, 35, 131, 0.24) 48.71%, rgba(69, 40, 150, 0.24) 78.16%, rgba(118, 36, 142, 0.24) 100%);*/
  }

  .cancelNavButton {
    display: none;
  }

  .menu_bar {
    display: none;
  }

  .mainHeader img {
    height: 100px;
    width: 100px;
  }

  .menu_section {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }

  .menu_sectionActive {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }

  .button_with_close {
    display: none;
  }

  .action_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
  }

  .button_with_wallet {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: 1px solid rgba(81, 66, 252, 1);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .button_with_wallet:hover {
    transform: scale(1.2);
  }
}
@media screen and (max-width: 849px) {
  .mainHeader {
    position: fixed;
    top: 0;
    text-decoration: none;
    align-items: center;
    height: 70px;
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #141423;
    color: white;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 50%);
    /*background: radial-gradient(30.8% 30.8% at 57.79% 54.71%, rgba(43, 24, 103, 0.24) 0%, rgba(56, 35, 131, 0.24) 48.71%, rgba(69, 40, 150, 0.24) 78.16%, rgba(118, 36, 142, 0.24) 100%);*/
  }

  .mainHeader img {
    height: 60px;
    width: 60px;
  }

  .cancelNavButton {
    color: white;
  }

  .mainHeader h2 {
    font-size: 15px;
  }

  .menu_section {
    position: absolute;
    z-index: 8999;
    height: 100vh;
    background-color: #141423;
    width: 100%;
    text-align: center;
    font-size: 28px;
    /* display: flex; */
    flex-direction: column;
    transform: translate(-200%);
    transition: all 0.3s ease 0s;
    gap: 30px;
    align-items: center;
  }
  .menu_sectionActive {
    position: absolute;
    z-index: 8999;
    height: 100vh;
    background-color: #141423;
    width: 100%;
    padding-left: 20%;
    font-size: 28px;
    /* display: flex; */
    flex-direction: column;
    transform: translate(0%);
    transition: all 0.3s ease 0s;
    gap: 30px;
    align-items: center;
  }

  .action_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    justify-content: space-evenly;
  }

  .button_with_wallet {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: 1px solid rgba(81, 66, 252, 1);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .button_with_wallet:hover {
    transform: scale(1.2);
  }

  .button_with_close {
    margin-left: 60%;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: 1px solid rgba(81, 66, 252, 1);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .button_with_close:hover {
    transform: scale(1.2);
  }
}
