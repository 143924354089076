.heroSection{
    background-image: url(../../assets/12.webp);
    background-size: cover;
    height: auto;
    padding-bottom: 30px;
}


.h4Text{
    text-align: center;
    font-size: 40px;
}

.subHeaderSection .horizontalDivider {
    margin-top: -45px;
}

.horizontalDivider{
    display: block;
    margin: auto;
    height: 3px;
    width: 60px;
    background-color: #0078cb;
}

.listOfDrops{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
    justify-content: center;
}