@media screen and (min-width:800px){
    .countDownText{
        font-weight:bolder;
        font-size:40px;
        margin-top:10px
    }
}
@media screen and (max-width:800px){
    .countDownText{
        font-weight:bolder;
        font-size:20px;
        margin-top:10px

    }
}