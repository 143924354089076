.nfts_section_flex{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    height: auto;
    justify-content: center;
    width: 100%;
}

.nft_filter_seciton{
    display: flex;
    flex-wrap:wrap;
    gap: 20px;
    padding: 20px;
}

.left_filter_section{
    display: flex;
    flex-wrap:wrap;
    gap: 30px;
}

.clear_filterBtn{
    padding: 5px;
    height: 50px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    color: white;
    background-color: #5142FC;
    border: none;
}

.button{
    padding: 5px;
    height: 50px;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 8px;
    color: white;
    background-color: #5142FC;
    border: none;
}
