@media screen and (min-width: 900px) {
  .mainProjectImg {
    height: auto;
    width: 400px;
    border-radius: 10px;
  }

  .projectSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 30px;
    align-items: center;
    margin-top: 100px;
  }

  .projectInfo {
    width: 50%;
  }

  .projectNameDetails {
    display: "flex";
    flex-direction: "column";
    align-content: "center";
  }

  .projectInfo h3 {
    margin-top: 30px;
    font-size: 50px;
  }

  .profileSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: -15px;
  }

  .profileSection p {
    font-size: 13px;
    margin-top: -20px;
  }

  .profileSection h4 {
    margin-top: 10px;
    font-size: 20px;
  }

  .profileSection img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .mintDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    margin-right: 20%;
  }

  .publicPrice {
    font-weight: 900;
    font-size: 40px;
    margin-top: -5px;
  }

  .mintPriceDetails p {
    color: #878787;
  }

  .blueWhiteButtonMob {
    display: none;
  }

  .blueWhiteButton {
    background-color: #218cff;
    border-radius: 8px;
    height: 40px;
    width: 120px;
    border: none;
    font-family: "DM Sans", sans-serif;
    color: white;
  }

  .verticalDivider {
    height: 80px;
    width: 1.5px;
    margin-top: 50px;
    background-color: #878787;
    border-radius: 10px;
  }
}

@media screen and (max-width: 900px) {
  .mainProjectImg {
    height: auto;
    width: 400px;
    border-radius: 10px;
  }

  .projectSection {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 30px;
    margin-top: 100px;
    align-items: center;
  }

  .projectInfo {
    width: 100%;
  }

  .projectInfo .mainWrap {
    padding: 10%;
  }

  .projectNameDetails {
    display: "flex";
    flex-direction: "column";
    align-content: "center";
  }

  .projectInfo h3 {
    text-align: left;
    margin-top: 30px;
    font-size: 30px;
  }

  .profileSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: -15px;
  }

  .profileSection p {
    font-size: 13px;
    margin-top: -20px;
  }

  .profileSection h4 {
    margin-top: 10px;
    font-size: 20px;
  }

  .profileSection img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .mintDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 10%; */
    width: 100%;
  }

  .publicPrice {
    font-weight: 900;
    font-size: 20px;
    margin-top: -5px;
  }

  .mintPriceDetails {
    width: 100%;
  }
  .mintPriceDetails p {
    color: #878787;
  }

  .blueWhiteButton {
    background-color: #218cff;
    border-radius: 8px;
    height: 40px;
    width: 70%;
    /* display: none; */
    border: none;
    font-family: "DM Sans", sans-serif;
    color: white;
  }
  .blueWhiteButtonMob {
    background-color: #218cff;
    border-radius: 8px;
    height: 40px;
    width: 70%;
    border: none;
    font-family: "DM Sans", sans-serif;
    color: white;
  }

  .verticalDivider {
    height: 80px;
    display: none;
    width: 1.5px;
    background-color: #878787;
    border-radius: 10px;
  }
}

.mintCounterSection {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.mintCounterSection .countButtons {
  border: solid 1px gray;
  height: 40px;
  width: 40px;
  font-size: 20px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.countButtons p {
  margin-top: 8px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.mintCounterSection .countHub {
  width: auto;
  padding-left: 50px;
  padding-right: 50px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px gray;
}

.countHub p {
  margin-top: 10px;
}

.progressBar {
  margin-bottom: 20px;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  border: solid 1px gray;
  background-color: white;
}

.innerBar {
  /* width: 80%; */
  height: 20px;
  border-radius: 10px;
  background-color: #218cff;
}
