@media screen and (min-width: 850px) {
  .traitSection {
    background-color: #7a798a;
    display: block;
    margin: auto;
    border-radius: 10px;
    height: auto;
    padding-bottom: 30px;
    width: 80%;
  }

  .traitHeaderSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .traitHeaderSection p {
    color: white;
    font: bolder;
  }
  .nftDetailsMainDiv {
    background-color: #141423;
    height: auto;
    padding-bottom: 30px;
  }

  .nftDetailsInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    gap: 30px;
  }

  .nftImageSection {
    height: 500px;
    width: 500px;
    background-color: transparent;
    border-radius: 10px;
  }

  .nftImageSection img {
    display: block;
    margin: auto;
    height: 80%;
    width: 80%;
    border-radius: 10px;
  }

  .nftImageSection video {
    background-color: transparent;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    height: 90%;
    width: 90%;
    border-radius: 10px;
  }

  .nftTextDetails {
    width: 55%;
  }

  .nftDetailsInner .nftTextDetails h2 {
    color: white;
    font-weight: bold;
    font-size: 25px;
  }

  .nftCreatorSection {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .nftCreatorDiv {
    height: 90px;
    width: 50%;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    background-color: #343444;
  }

  .nftCreatorDiv .nftCreatorImg {
    height: 60px;
    width: 60px;
    background-color: #7a798a;
    border-radius: 10px;
    margin-left: 10px;
  }

  .nftCreatorImg img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }

  .nftCreatorDiv .nftCreatorText {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    gap: 10px;
  }

  .nftCreatorText p {
    color: #7a798a;
    font-size: 15px;
  }
  .nftCreatorText h3 {
    color: white;
    font-size: 18px;
    margin-top: -10px;
    font-weight: bold;
  }

  .nftTextDetails .nftDescription p {
    color: white;
  }

  .nftPriceSection {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
  }
  .nftBidSection {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }

  .priceInfoSection {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .nftPriceDiv {
    height: 70px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background-color: #343444;
  }

  .countDownSection {
    height: 70px;
    width: 100%;
    border-radius: 10px;
    gap: 20px;
    align-items: center;
    padding: 5px;
    background-color: #343444;
  }
  .currentBidDiv {
    height: 60px;
    width: 100%;
    border-radius: 10px;
    gap: 20px;
    align-items: center;
    padding: 5px;
    background-color: #343444;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }

  .currentBidDiv p {
    text-align: center;
    color: white;
    font-size: 16px;
    margin-left: 10px;
  }

  .currentBidDiv b {
    text-align: center;
    color: white;
    font-size: 15px;
  }

  .nftPriceDiv p {
    color: #7a798a;
    font-size: 15px;
    margin-left: 15px;
  }

  .nftPriceDiv h3 {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .button_style_one {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    margin-top: 15px;
    /* padding-left: 50px;
        padding-right: 50px; */
    font-size: 18px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: 90%;
    height: 50px;
    border: 1px solid rgba(81, 66, 252, 1);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .nftListFormSection {
    display: block;
    margin: auto;
    height: auto;
    padding-bottom: 40px;
    width: 95%;
    background-color: #141423;
    border-radius: 10px;
  }

  .nftListFormSection h4 {
    color: white;
    font-size: 24px;
    padding: 20px;
  }

  .inputDiv {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
  }

  .nftListFormSection .inputDiv p {
    color: white;
    font-size: 15px;
  }

  .nftListFormSection .inputDiv input {
    border-radius: 10px;
    height: 45px;
    padding-left: 20px;
    color: white;
    caret-color: white;
    font-weight: bold;
    font-size: 16px;
    font-family: "Urbanist", sans-serif;
    width: 50%;
    background-color: #7a798a;
    border: 1px solid #343444;
  }

  .countDownBlocksWrap {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    font-size: 20px;
    color: white;
  }

  .countDownBlock {
    background-color: #5142fc;
    border-radius: 8px;
    height: 50px;
    width: 50px;
    align-items: center;
    color: white;
  }

  .countDownBlock p {
    margin-top: 10px;
    text-align: center;
  }

  .acceptBidBtn {
    height: 40px;
    width: 150px;
    border-radius: 8px;
    color: white;
    border: none;
  }
}

@media screen and (max-width: 849px) {
  .traitSection {
    background-color: #7a798a;
    border-radius: 10px;
    height: auto;
    display: block;
    margin: auto;
    padding-bottom: 30px;
    width: 80%;
  }

  .traitHeaderSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .traitHeaderSection p {
    color: white;
    font: bolder;
  }
  .nftDetailsMainDiv {
    background-color: #141423;
    height: auto;
    width: 100%;
    padding-bottom: 30px;
  }

  .nftDetailsInner {
    display: block;
    margin: auto;
    padding: 20px;
    width: 100%;
    gap: 30px;
  }

  .nftImageSection {
    display: block;
    margin: auto;
    height: auto;
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .nftImageSection img {
    display: block;
    margin: auto;
    height: 80%;
    width: 80%;
    border-radius: 10px;
  }

  .nftImageSection video {
    background-color: transparent;
    display: block;
    padding-top: 10px;
    height: 90%;
    width: 90%;
    border-radius: 10px;
  }

  .nftTextDetails {
    width: 100%;
  }

  .nftDetailsInner .nftTextDetails h2 {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 25px;
  }

  .nftCreatorSection {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;
  }

  .nftCreatorDiv {
    height: 90px;
    width: 80%;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #343444;
  }

  .nftCreatorDiv .nftCreatorImg {
    height: 60px;
    width: 60px;
    background-color: #7a798a;
    border-radius: 10px;
    margin-left: 10px;
  }

  .nftCreatorImg img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }

  .nftCreatorDiv .nftCreatorText {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    gap: 10px;
  }

  .nftCreatorText p {
    color: #7a798a;
    font-size: 15px;
  }
  .nftCreatorText h3 {
    color: white;
    font-size: 18px;
    margin-top: -10px;
    font-weight: bold;
  }

  .nftTextDetails .nftDescription p {
    color: white;
  }

  .nftPriceSection {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
  }
  .nftBidSection {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }

  .priceInfoSection {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  .nftPriceDiv {
    height: 70px;
    width: 80%;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #343444;
  }

  .countDownSection {
    height: 70px;
    width: 80%;
    border-radius: 10px;
    gap: 20px;
    align-items: center;
    padding: 5px;
    background-color: #343444;
  }
  .currentBidDiv {
    height: 60px;
    width: 80%;
    border-radius: 10px;
    gap: 20px;
    align-items: center;
    padding: 5px;
    background-color: #343444;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }

  .currentBidDiv p {
    text-align: center;
    color: white;
    font-size: 16px;
    margin-left: 10px;
  }

  .currentBidDiv b {
    text-align: center;
    color: white;
    font-size: 15px;
  }

  .nftPriceDiv p {
    color: #7a798a;
    font-size: 15px;
    margin-left: 15px;
  }

  .nftPriceDiv h3 {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .button_style_one {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    margin-top: 15px;
    font-size: 18px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    margin-left: 0px;
    width: 90%;
    height: 50px;
    border: 1px solid rgba(81, 66, 252, 1);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
  }

  .countDownBlocksWrap {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    width: 95%;
    margin-top: 10px;
    justify-content: center;
    font-size: 20px;
    color: white;
  }

  .countDownBlock {
    background-color: #5142fc;
    border-radius: 8px;
    height: 50px;
    width: 50px;
    align-items: center;
    color: white;
  }

  .countDownBlock p {
    margin-top: 10px;
    text-align: center;
  }

  .acceptBidBtn {
    height: 40px;
    width: 150px;
    border-radius: 8px;
    color: white;
    border: none;
  }
}
