@media screen and (min-width:950px){
    .edit_dash_flex{
        display: flex;
        flex-direction: row;
        height: auto;
        padding-bottom: 100px;
        gap: 40px;
    }
    
    .profile_img_edit{
        height:430px;
        width: 300px;
        border-radius: 20px;
        margin-top: 30px;
        margin-left: 40px;
        border: none;
        padding:10px;
        padding-bottom: 30px;
        background-color: #343444;
    }
    
    /* .profile_image_view{
        background-color: #7A798A;
        display: block;
        height: 300px;
        width: 290px;
        border-radius: 20px;
        border: none;
        margin: auto;
    } */
    
    .profile_image_view img {
            height: 80%;
            width: 80%;
            border-radius: 10px;
            margin-top: 9%;
            margin-left: 9%;
    }
    
    .button_style_one{
        display: flex;
        flex-direction: row;
        background-color: transparent;
        justify-content: center;
        border-radius: 20px;
        color: white;
        gap:20px;
        margin-top:  15px;
        margin-left: 5%;
        /* padding-left: 50px;
        padding-right: 50px; */
        font-family: 'Urbanist', sans-serif;
        align-items: center;
        font-size: 16px;
        width: 90%;
        height: 50px;
        border : 1px solid rgba(81, 66, 252, 1);
        transition: all .4s ease-in-out;
        cursor: pointer;
    }
    
    .button_style_one:hover{
        border:none;
        background-color:white;
        color:#343444;
        transform: scale(0.9);
    }
    
    .edit_data_section .edit_data_header{
        color: white;
        font-weight: bold;
        margin-left: 30px;
        font-size: 22px;
    }
    
    .uploadCoverImage_flex{
        display: flex;
        flex-direction: row;
        margin-left: 30px;
        gap:30px
    }
    
    
    .upload_cover_image_div{
        height: 90px;
        width: 270px;
        border-radius: 10px;
        border: 1px solid #343444;
    }
    
    .upload_cover_image_div img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
    
    .inputFieldSectionFlex{
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
    
    .inputFieldInnerSection{
        margin-left: 30px;
        width: 60%;
    }
    
    .inputFieldInnerSection h4{
        color: white;
        font-weight: bold;
        font-size: 20px;
    }
    
    .inputDiv{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .inputFieldInnerSection .inputDiv p {
        color: white;
        font-size: 15px;
    }
    
    .inputFieldInnerSection .inputDiv input {
        border-radius: 10px;
        height: 45px;
        padding-left: 20px;
        color: #7A798A;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Urbanist', sans-serif;
        width: 90%;
        background-color: transparent;
        border: 1px solid #343444
    }
    .inputFieldInnerSection .inputDiv textarea {
        border-radius: 10px;
        height: 200px;
        max-height: 200px;
        min-height: 100px;
        padding: 20px;
        color: #7A798A;
        max-width: 90%;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Urbanist', sans-serif;
        width: 90%;
        background-color: transparent;
        border: 1px solid #343444
    }
    
    .inputFieldInnerSection .inputDiv input::placeholder{
        padding-left: 20px;
        color: #7A798A;
        font-size: 16px;
        font-family: 'Urbanist', sans-serif;
    
    }
}


@media screen and (max-width:949px){
    .edit_dash_flex{
        display: flex;
        flex-direction: column;
        height: auto;
        padding-bottom: 100px;
        gap: 40px;
    }
    
    .profile_img_edit{
        height:430px;
        width: 300px;
        border-radius: 20px;
        margin-top: 30px;
        margin-left: 40px;
        border: none;
        padding:10px;
        padding-bottom: 30px;
        background-color: #343444;
    }
    
    /* .profile_image_view{
        background-color: #7A798A;
        display: block;
        height: 300px;
        width: 290px;
        border-radius: 20px;
        border: none;
        margin: auto;
    } */
    
    .profile_image_view img {
            height: 80%;
            width: 80%;
            border-radius: 10px;
            margin-top: 9%;
            margin-left: 9%;
    }
    
    .button_style_one{
        display: flex;
        flex-direction: row;
        background-color: transparent;
        justify-content: center;
        border-radius: 20px;
        color: white;
        gap:20px;
        margin-top:  15px;
        margin-left: 5%;
        /* padding-left: 50px;
        padding-right: 50px; */
        font-family: 'Urbanist', sans-serif;
        align-items: center;
        font-size: 16px;
        width: 90%;
        height: 50px;
        border : 1px solid rgba(81, 66, 252, 1);
        transition: all .4s ease-in-out;
        cursor: pointer;
    }
    
    .button_style_one:hover{
        border:none;
        background-color:white;
        color:#343444;
        transform: scale(0.9);
    }
    
    .edit_data_section .edit_data_header{
        color: white;
        font-weight: bold;
        margin-left: 30px;
        font-size: 22px;
    }
    
    .uploadCoverImage_flex{
        display: flex;
        flex-wrap:wrap ;
        margin-left: 30px;
        gap:30px
    }
    
    
    .upload_cover_image_div{
        height: 90px;
        width: 80%;
        border-radius: 10px;
        border: 1px solid #343444;
    }
    
    .upload_cover_image_div img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
    
    .inputFieldSectionFlex{
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
    
    .inputFieldInnerSection{
        margin-left: 30px;
        width: 80%;
    }
    
    .inputFieldInnerSection h4{
        color: white;
        font-weight: bold;
        font-size: 20px;
    }
    
    .inputDiv{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .inputFieldInnerSection .inputDiv p {
        color: white;
        font-size: 15px;
    }
    
    .inputFieldInnerSection .inputDiv input {
        border-radius: 10px;
        height: 45px;
        padding-left: 20px;
        color: #7A798A;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Urbanist', sans-serif;
        width: 90%;
        background-color: transparent;
        border: 1px solid #343444
    }
    .inputFieldInnerSection .inputDiv textarea {
        border-radius: 10px;
        height: 200px;
        max-height: 200px;
        min-height: 100px;
        padding: 20px;
        color: #7A798A;
        max-width: 90%;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Urbanist', sans-serif;
        width: 90%;
        background-color: transparent;
        border: 1px solid #343444
    }
    
    .inputFieldInnerSection .inputDiv input::placeholder{
        padding-left: 20px;
        color: #7A798A;
        font-size: 16px;
        font-family: 'Urbanist', sans-serif;
    
    }
}



