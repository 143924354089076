.menuSection{
    color: white;
    display: flex;
    flex-direction: row; 
    gap: 40px;  
    width: auto;
    color: white;
    padding: 10px;
}

.menuActive{
    cursor: pointer;
    transition: all .4s ease-in-out;
    color: #5142FC;
}

.menu{
    cursor: pointer;
    transition: all .4s ease-in-out;
    color: white;
}

.menuSection .barActive {
    height: 5px;
    width:95% ;
    background-color: #5142FC;
    border-radius: 5px;
    transition: all .4s ease-in-out;
}

.bar{
    height: 5px;
    width:95% ;
    background-color: transparent;
    border-radius: 5px;
    transition: all .4s ease-in-out;
}

.menuDivider{
    height: 2px;
    width: 95%;
    background-color: #1F1F2C;
    margin-top: -13.6px;
}

.nftHistory{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .4s ease-in-out;
    gap: 30px;
}

.activityUserDetails{
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
}

.userImageDiv{
    height: 60px;
    width: 60px;
    background-color: #7A798A;
    border-radius: 10px;
    margin-left: 10px;
}

.userImage{
    height: 100%;
    width:100%;
    border-radius: 10px;
}

.activityDetails{
    display: flex;
    flex-direction: column;
    gap: 0px;
    color: white;
}

.historyValue {
    margin-right: 10%;
}

.historyValue b {
    color: white;
}

.allNftHistoryWrap{
    transition: all .4s ease-in-out;
    height: auto;
    overflow-x: hidden;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-color: white;
}


.allNftHistoryWrap::-webkit-scrollbar {
    width: 5px;
}

.allNftHistoryWrap::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 5px;
}

.allNftHistoryWrap::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #5142FC;
    border-radius: 5px;
}

.acceptBidBtn{
    height: 40px;
    width: 150px;
    border-radius: 8px;
    color: white;
    background-color: #5142FC;
    border: none;
}