.selectorSection {
    background-color: #343444;
    display:flex ;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    height: 40px;
    width: 150px;
    border-radius: 8px;
    border: none;
    color: white;
}
.selectorSectionActive {
    background-color: #343444;
    display:flex ;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    height: 40px;
    width: 150px;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
    color: white;
}

.optionSection{
    position: absolute;
    height: auto;
    width: 150px;
    background-color: #343544;
    border-radius: 8px;
    display: none;
    top: 100%;
    padding:5px;
    color: white;
    padding-bottom: 30px;
    display: none;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 10;
    transition: all .4s ease-in-out;
    
}
.optionSectionActive{
    position: absolute;
    height: auto;
    width: 150px;
    background-color: #343544;
    border-radius: 8px;
    padding:5px;
    color: white;
    display: block;
    padding-bottom: 30px;
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 10;
    transition: all .4s ease-in-out;
}


.optionActive{
    height: 50px;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(81, 66, 252, 1);
    color: white;
}

.option{
    height: 30px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    color: white;
    transition: all .4s ease-in-out;
}

.option p {
    padding-top: 10px;
}

.optionActive p {
    padding-top: 10px;
}

.option:hover{
    height: 50px;
    border-radius: 5px;
    background-color: rgba(81, 66, 252, 1);

}

.optionActive p {
    text-align: center;
}