@media screen and (min-width: 850px) {
  h1 {
    font-size: 50px;
  }

  .hero_section {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
    max-width: 100%;
  }

  .hero_text {
    max-width: 50%;
    padding: 30px;
  }

  .hero_text h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    color: #c4c4c4;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
  }

  .hero_text p {
    color: white;
    text-align: left;
  }

  .action_hero_section {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .action_btn_hero-trans {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    height: 55px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: 1px solid rgba(81, 66, 252, 1);
  }
  .action_btn_hero-white {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 20px;
    color: black;
    gap: 20px;
    height: 55px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: none;
    /* border : 1px solid rgba(81, 66, 252, 1) */
  }

  .image_section {
    margin-top: 20px;
  }

  .image_section img {
    height: 550px;
    border-radius: 10px;
  }

  .setup_intro_section {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 20px;
  }

  .set_up_column {
    display: flex;
    flex-direction: column;
    max-width: 30%;
  }

  .live_auction_section {
    background-color: black;
    height: auto;
    padding-bottom: 30px;
    width: 100%;
  }

  .live_auction_section h2 {
    text-align: center;
  }

  .live_auction_section_flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    height: auto;
    width: 100%;
  }

  .live_auction_section h2 {
    margin-left: 10%;
    float: left;
    font-size: 35px;
    margin-top: 20px;
    font-weight: 900;
    color: white;
  }
}

@media screen and (max-width: 849.99px) {
  h1 {
    font-size: 50px;
  }

  .hero_section {
    display: flex;
    flex-direction: column;
  }

  .hero_text {
    padding: 30px;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    text-align: center;
  }

  .hero_text h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 68px;
    color: #c4c4c4;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;
  }

  .hero_text p {
    color: white;
    text-align: left;
  }

  .action_hero_section {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .action_btn_hero-trans {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 20px;
    color: white;
    gap: 20px;
    height: 55px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: 1px solid rgba(81, 66, 252, 1);
  }
  .action_btn_hero-white {
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 20px;
    color: black;
    gap: 20px;
    height: 55px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    width: auto;
    border: none;
    /* border : 1px solid rgba(81, 66, 252, 1) */
  }

  .image_section {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }

  .image_section img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 70%;
    padding-bottom: 40px;
  }

  .setup_intro_section {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 20px;
  }

  .set_up_column {
    display: flex;
    flex-direction: column;
    max-width: 30%;
  }

  .live_auction_section {
    background-color: black;
    height: auto;
    padding-bottom: 30px;
    width: 100%;
  }

  .live_auction_section h2 {
    text-align: center;
  }

  .live_auction_section_flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    height: auto;
    width: 100%;
  }

  .live_auction_section h2 {
    margin-left: 10%;
    float: left;
    font-size: 35px;
    margin-top: 20px;
    font-weight: 900;
    color: white;
  }
}
