.parentCard {
  /* position: relative; */
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 10px;
  height: 300px;
  width: 20rem;
  border: solid 1px #ccc;
  display: block;
  margin: auto;
  margin-top: 10px;
}

.imageDiv {
  width: 100%;
  height: 130px;
}

.imageDiv img {
  width: 100%;
  height: 130px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.avaterImgDiv {
  width: 70px;
  height: 70px;
  background-color: white;
  position: relative;
  border-radius: 100%;
  top: -30px;
  left: 30px;
}

.avaterImgDiv img {
  width: 70px;
  height: 70px;
  scale: 0.85;
  border-radius: 100%;
}

.collectionName {
  margin-top: -25px;
  color: white;
  font-weight: bolder;
  font-size: 20px;
  padding-left: 20px;
}

.viewMoreButton {
  background-color: white;
  border-radius: 10px;
  height: 50px;
  width: 80%;
  display: block;
  margin: auto;
  color: black;
  text-align: center;
  outline: none;
  border: 0;
}
