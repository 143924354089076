.button_style_one{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    justify-content: center;
    border-radius: 20px;
    color: white;
    gap:20px;
    margin-top:  15px;
    margin-left: 0px;
    font-size: 18px;
    font-family: 'Urbanist', sans-serif;
    align-items: center;
    width: 90%;
    height: 50px;
    border : 1px solid rgba(81, 66, 252, 1);
    transition: all .4s ease-in-out;
    cursor: pointer;
}

.nftListFormSection{
    display: block;
    margin: auto;
    height: auto;
    padding-bottom: 40px;
    width: 95%;
    background-color: #141423;
    border-radius: 10px;
}

.nftListFormSection h4 {
    color:white;
    font-size: 24px;
    padding: 20px;
}

.inputDiv{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 20px;
}

.nftListFormSection .inputDiv p {
    color: white;
    font-size: 15px;
}

.nftListFormSection .inputDiv input {
    border-radius: 10px;
    height: 45px;
    padding-left: 20px;
    color: white;
    caret-color: white;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Urbanist', sans-serif;
    width: 50%;
    background-color: #7A798A;
    border: 1px solid #343444
}


.nftSaleModeFlexWrap{
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: space-around;
    align-items: center;
}

.nftSaleModeDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 30px;
    width: 40%;
    padding: 10px;
    align-items: center;
    background-color: #343444;
    border-radius: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.4s;

}

.nftSaleModeDiv:hover{
    transition: all ease-in-out 0.4s;
    background-color: white;
}

.nftSaleModeDiv p {
    font-size: 14px;
    color: #8A8AA0;
}

.nftSaleModeDiv svg{
    color: #8A8AA0;
}
.nftSaleModeActiveDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 30px;
    width: 40%;
    padding: 10px;
    align-items: center;
    background-color: white;
    transition: all ease-in-out 0.4s;
    cursor: pointer;
    border-radius: 10px;
}

.nftSaleModeActiveDiv p {
    font-size: 14px;
    color: #5142FC;
}

.nftSaleModeActiveDiv svg{
    color: #5142FC;
}