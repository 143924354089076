.collectionText {
  color: white;
  font-weight: bolder;
  font-size: 50px;
  text-decoration: underline;
  margin-left: 30px;
}

.listOfCollections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
